















































































































































































































































































.userLogin {
  .newTitleTwo {
    background: #fff;
  }
  /* @import "../../assets/css/puliceStyle.css"; */

  input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px white inset !important;
  }

  .pageTitle {
    font-size: 0.48rem;
    font-weight: Bold;
    line-height: 0.78rem;
    margin-bottom: 1.2rem;
  }

  .text-1 {
    font-size: 0.24rem;
  }

  .text-1 span {
    color: #5b54ea;
  }

  .isShowPwd {
    display: inline-block;
    float: right;
    margin-top: 0.2rem;
    width: 0.34rem;
    height: 0.28rem;
  }

  .isShowPwd img {
    width: 100%;
    height: 100%;
  }

  .body1StyleInputStyle {
    border: 0px;
    font-size: 0.28rem;
    width: 80%;
    line-height: 0.8rem;
  }

  .bodyStyleDivStyle {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 0.1rem;
  }

  .body1Style {
    padding: 0.3rem;
  }

  .bottom-ve {
    width: 2.72rem;
    display: block;
    bottom: 0.2rem;
    padding-top: 1.2rem;
  }

  .pc_in {
    position: relative;
    float: left;
    width: 6.7rem;
    height: 1.12rem;
    border: none;
  }

  .pc_in .divbox {
    display: flex;
    width: 100%;
    height: 1.12rem;
  }

  .pc_in .divbox span {
    flex: 1;
    height: 100%;
    border-bottom: #e6e8ed solid 1px;
    border-right: none;
    margin: 0 0.16rem;
  }

  .pc_in .divbox span.bl {
    border-color: #000;
  }

  .userLogin {
    width: 100%;
    overflow: hidden;
  }

  .pc_in p {
    position: absolute;
    width: 120%;
    height: 1.12rem;
    line-height: 1.12rem;
    top: 0;
    left: 0;
    background: none;
    overflow: hidden;
  }

  .pc_in p input {
    float: left;
    width: 180%;
    height: 0.8rem;
    line-height: 0.8rem;
    margin-top: 0.2rem;
    letter-spacing: 0.84rem;
    background: none;
    text-indent: 0.4rem;
    font-size: 0.48rem;
  }
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;